import React, { useState, useEffect, useContext } from 'react';
import NavBar from '../components/navbar';
import NewJobModal from '../components/new-job-form-modal';
import EditModal from '../components/edit-job-modal';
import CancelJobModal from '../components/cancel-job-modal';
import DeleteJobModal from '../components/delete-job-modal';
import DuplicateJobModal from '../components/duplicate-job-modal';
import AppContext from '../lib/app-context';
import Redirect from '../components/redirect';

export default function ProductionSheet(props) {
  // this grabs all the years from the database and creates an array in state👇🏼
  const [yearsList, setYearsList] = useState([]);
  useEffect(() => {
    fetch('/api/years')
      .then(res => res.json())
      .then(yearsList => {
        setYearsList(yearsList);
      });
  }, []);

  // this will display all weeks pertaining to whichever year is selected inside the NewJob and EditJob forms👇🏼
  const [weeksList, setWeeksList] = useState([]);

  // this will be used t display weeks at top of Production sheet page 👇🏼
  const [newWeeksList, setNewWeeksList] = useState([]);

  const [totalWeekCopies, setTotalWeekCopies] = useState('');
  const [totalYearCopies, setTotalYearCopies] = useState('');

  const [values, setValues] = useState({
    yearId: '',
    weekId: '',
    jobId: '',
    year: '',
    week: '',
    companyId: '',
    companyName: '',
    companyAddressId: '',
    distributorName: '',
    distributorId: '',
    distributorAddressId: '',
    jobNumber: '',
    paperSize: '',
    paperWeight: '',
    shippingStatus: '',
    paymentStatus: '',
    orderStatus: '',
    distributorCopies: '',
    storeCopies: '',
    officeCopies: '',
    instructions: '',
    shipDate: '',
    dueDate: '',
    inHomeDate: '',
    headline: ''
  });

  // FETCH method GETS all distributors in database so they can be selected in the form 👇🏼
  const [distributors, setDistributors] = useState([]);
  useEffect(() => {
    fetch('/api/distributors')
      .then(res => res.json())
      .then(distributors => {
        setDistributors(distributors);
      });
  }, []);

  // FETCH method GETS all companies in database so they can be selected in the form 👇🏼
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    fetch('/api/companies')
      .then(res => res.json())
      .then(companies => {
        setCompanies(companies);
      });
  }, []);

  // These useState variables allow the "search" button to work properly when pressed
  // it gets cleared immediately after submitting the form 👇🏼
  const [searchParams, setSearchParams] = useState({
    yearId: '',
    weekId: '',
    year: '',
    week: ''
  });

  // These useState variables grab the value from searchParams, and store them so
  // they can be rendered 👇🏼
  const [weekAndYear, setWeekAndYear] = useState({
    year: '',
    yearId: '',
    week: '',
    weekId: ''
  });

  const [selectedJobId, setSelectedJobId] = useState('');

  const prepareToRemove = event => {
    event.preventDefault();
    const jobId = Number(event.target.getAttribute('id'));
    setSelectedJobId(jobId);
  };

  // *** Modal state and functions for delete job, add job, and edit job*** 👇🏼
  const [overlay, setOverlay] = useState('overlay d-none');
  const [editOverlay, setEditOverlay] = useState('overlay d-none');
  const [newJobOverlay, setNewJobOverlay] = useState('overlay d-none');
  const [duplicateJobOverlay, setDuplicateJobOverlay] = useState('overlay d-none');
  const [cancelModalStyle, setCancelModalStyle] = useState('position-fixed cancel-modal-wrapper col-10 col-lg-8 d-none');
  const [deleteModalStyle, setDeleteModalStyle] = useState('position-fixed delete-modal-wrapper col-10 col-lg-8 d-none');
  const [editModalStyle, setEditModalStyle] = useState('position-absolute edit-modal-wrapper col-10 bg-white rounded d-none');
  const [newJobModalStyle, setNewJobModalStyle] = useState('position-absolute new-job-modal-wrapper col-10 bg-white rounded d-none');
  const [duplicateJobModalStyle, setDuplicateJobModalStyle] = useState('position-absolute duplicate-modal-wrapper col-10 bg-white rounded d-none');

  const [duplicateJobMessage, setDuplicateJobMessage] = useState('text-danger d-none');

  const showCancelModal = event => {
    event.preventDefault();
    setOverlay('overlay');
    setCancelModalStyle('position-fixed cancel-modal-wrapper col-10 col-lg-8');
  };

  const showDeleteModal = event => {
    event.preventDefault();
    setOverlay('overlay');
    setDeleteModalStyle('position-fixed delete-modal-wrapper col-10 col-lg-8');
  };

  const hideCancelModal = event => {
    event.preventDefault();
    setOverlay('overlay d-none');
    setCancelModalStyle('position-fixed cancel-modal-wrapper col-10 col-lg-8 d-none');
    setSelectedJobId('');
  };

  const hideDeleteModal = event => {
    event.preventDefault();
    setOverlay('overlay d-none');
    setDeleteModalStyle('position-fixed delete-modal-wrapper col-10 col-lg-8 d-none');
    setSelectedJobId('');
  };

  const showEditModal = event => {
    event.preventDefault();
    setEditOverlay('overlay');
    setEditModalStyle('position-absolute edit-modal-wrapper col-10 bg-white rounded');
    const jobId = event.target.id;
    fetch(`/api/jobs/${jobId}`)
      .then(response => response.json())
      .then(job => {
        setValues({
          jobId: job.jobId,
          yearId: job.yearId,
          weekId: job.weekId,
          companyName: job.companyName,
          companyId: job.companyId,
          companyAddressId: job.companyAddressId,
          distributorName: job.distributorName,
          distributorId: job.distributorId,
          distributorAddressId: job.distributorAddressId,
          jobNumber: job.jobNumber,
          paperSize: job.paperSize,
          paperWeight: job.paperWeight,
          shippingStatus: job.shippingStatus,
          paymentStatus: job.paymentStatus,
          orderStatus: job.orderStatus,
          distributorCopies: job.distributorCopies,
          storeCopies: job.storeCopies,
          officeCopies: job.officeCopies,
          instructions: job.instructions,
          shipDate: job.shipDate,
          dueDate: job.dueDate,
          inHomeDate: job.inHomeDate,
          headline: job.headline
        });
      });
  };

  const hideEditModal = event => {
    event.preventDefault();
    setEditOverlay('overlay d-none');
    setEditModalStyle('position-absolute edit-modal-wrapper col-10 bg-white rounded d-none');
    setDuplicateJobMessage('text-danger d-none');
    closeModal();
  };

  const showNewJobModal = event => {
    event.preventDefault();
    setNewJobOverlay('overlay');
    setNewJobModalStyle('position-absolute new-job-modal-wrapper col-10 bg-white rounded');
  };

  const hideNewJobModal = event => {
    event.preventDefault();
    setNewJobOverlay('overlay d-none');
    setNewJobModalStyle('position-absolute new-job-modal-wrapper col-10 bg-white rounded d-none');
    setDuplicateJobMessage('text-danger d-none');
    closeModal();
  };

  const showDuplicateJobModal = event => {
    event.preventDefault();
    setDuplicateJobOverlay('overlay');
    setDuplicateJobModalStyle('position-absolute duplicate-modal-wrapper col-10 bg-white rounded');
    const jobId = event.target.id;
    fetch(`/api/jobs/${jobId}`)
      .then(response => response.json())
      .then(job => {
        setValues({
          jobId: job.jobId,
          // yearId, weekId intentionally left blank
          yearId: '',
          weekId: '',
          companyName: job.companyName,
          companyId: job.companyId,
          companyAddressId: job.companyAddressId,
          distributorName: job.distributorName,
          distributorId: job.distributorId,
          distributorAddressId: job.distributorAddressId,
          jobNumber: job.jobNumber,
          paperSize: job.paperSize,
          paperWeight: job.paperWeight,
          shippingStatus: job.shippingStatus,
          paymentStatus: job.paymentStatus,
          orderStatus: job.orderStatus,
          distributorCopies: job.distributorCopies,
          storeCopies: job.storeCopies,
          officeCopies: job.officeCopies,
          instructions: job.instructions,
          shipDate: job.shipDate,
          dueDate: job.dueDate,
          inHomeDate: job.inHomeDate,
          headline: job.headline
        });
      });
  };

  const hideDuplicateJobModal = event => {
    event.preventDefault();
    setDuplicateJobOverlay('overlay d-none');
    setDuplicateJobModalStyle('position-absolute edit-modal-wrapper col-10 bg-white rounded d-none');
    setDuplicateJobMessage('text-danger d-none');
    closeModal();
  };

  const handleYearChange = event => {
    event.persist();
    for (let i = 0; i < yearsList.length; i++) {
      if (Number(event.target.innerText) === yearsList[i].year) {
        fetch(`/api/weeks/${yearsList[i].yearId}`)
          .then(res => res.json())
          .then(weeksList => {
            setNewWeeksList(weeksList);
          })
          .then(() => {
            fetch(`/api/yearly-total-copies/${yearsList[i].yearId}`)
              .then(res => res.json())
              .then(totalYearCopies => {
                setTotalYearCopies(totalYearCopies);
              })
              .then(() => {
                setSearchParams({
                  ...searchParams,
                  yearId: yearsList[i].yearId,
                  year: yearsList[i].year
                });
              });
          });
      }
    }
  };

  // All code from "START 🏁" to "FINISH 🏁" is used in NewJobModal and EditJobModal 👇🏼
  // START 🏁

  const handleYearIdChange = event => {
    event.persist();
    for (let i = 0; i < yearsList.length; i++) {
      if (Number(event.target.value) === yearsList[i].year) {
        fetch(`/api/weeks/${yearsList[i].yearId}`)
          .then(res => res.json())
          .then(weeksList => {
            setWeeksList(weeksList);
          });
        setValues(values => ({
          ...values,
          yearId: yearsList[i].yearId,
          year: yearsList[i].year
        }));
      }
    }
  };

  const handleWeekIdChange = event => {
    event.persist();
    for (let i = 0; i < weeksList.length; i++) {
      if (Number(event.target.value) === weeksList[i].week) {
        setValues(values => ({
          ...values,
          weekId: weeksList[i].weekId,
          week: weeksList[i].week
        }));
      }
    }
  };

  const handleDistributorIdChange = event => {
    event.persist();
    for (let i = 0; i < distributors.length; i++) {
      if (event.target.value === distributors[i].distributorName) {
        setValues(values => ({
          ...values,
          distributorId: distributors[i].distributorId,
          distributorAddressId: distributors[i].distributorAddressId,
          distributorName: distributors[i].distributorName
        }));
      }
    }
  };

  const handleCompanyIdChange = event => {
    event.persist();
    for (let i = 0; i < companies.length; i++) {
      if (event.target.value === companies[i].companyName) {
        setValues(values => ({
          ...values,
          companyId: companies[i].companyId,
          companyAddressId: companies[i].companyAddressId,
          companyName: companies[i].companyName
        }));
      }
    }
  };

  const handleJobNumberChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      jobNumber: event.target.value
    }));

  };

  const handlePaperSizeChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      paperSize: event.target.value
    }));
  };
  const handlePaperWeightChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      paperWeight: event.target.value
    }));
  };
  const handleShippingStatusChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      shippingStatus: event.target.value
    }));
  };
  const handlePaymentStatusChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      paymentStatus: event.target.value
    }));
  };
  const handleOrderStatusChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      orderStatus: event.target.value
    }));
  };
  const handleDistributorCopiesChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      distributorCopies: Number(event.target.value)
    }));
  };
  const handleOfficeCopiesChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      officeCopies: Number(event.target.value)
    }));
  };
  const handleStoreCopiesChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      storeCopies: Number(event.target.value)
    }));
  };
  const handleInstructionsChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      instructions: event.target.value
    }));
  };
  const handleShipDateChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      shipDate: event.target.value
    }));
  };
  const handleDueDateChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      dueDate: event.target.value
    }));
  };
  const handleInHomeDateChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      inHomeDate: event.target.value
    }));
  };
  const handleHeadlineChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      headline: event.target.value
    }));
  };

  function addJob(newJob) {
    fetch('/api/new-job', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newJob)
    })
      .then(response => response.json())
      .then(newJob => {
        const jobList = [...props.jobs];
        if (jobList.length === 0) {
          return null;
        } else if (newJob.yearId === weekAndYear.yearId && newJob.weekId === weekAndYear.weekId) {
          const newJobList = jobList.concat(newJob);
          props.setJobs(newJobList);
        }
      })
      .then(() => {
        const params = {
          yearId: weekAndYear.yearId,
          weekId: weekAndYear.weekId
        };
        fetch(`/api/weekly-total-copies/${params.yearId}/${params.weekId}`)
          .then(res => res.json())
          .then(totalWeekCopies => {
            setTotalWeekCopies(totalWeekCopies);
          })
          .then(() => {
            fetch(`/api/yearly-total-copies/${params.yearId}`)
              .then(res => res.json())
              .then(totalYearCopies => {
                setTotalYearCopies(totalYearCopies);
              });
          });
      })
      .catch(console.error);
  }

  function duplicateJob(duplicatedJob) {
    fetch('/api/new-job', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(duplicatedJob)
    })
      .then(response => response.json())
      .then(duplicatedJob => {
        const jobList = [...props.jobs];
        if (jobList.length === 0) {
          return null;
        } else if (duplicatedJob.yearId === weekAndYear.yearId && duplicatedJob.weekId === weekAndYear.weekId) {
          const newJobList = jobList.concat(duplicatedJob);
          props.setJobs(newJobList);
        }
      })
      .then(() => {
        const params = {
          yearId: weekAndYear.yearId,
          weekId: weekAndYear.weekId
        };
        fetch(`/api/weekly-total-copies/${params.yearId}/${params.weekId}`)
          .then(res => res.json())
          .then(totalWeekCopies => {
            setTotalWeekCopies(totalWeekCopies);
          })
          .then(() => {
            fetch(`/api/yearly-total-copies/${params.yearId}`)
              .then(res => res.json())
              .then(totalYearCopies => {
                setTotalYearCopies(totalYearCopies);
              });
          });
      })
      .catch(console.error);
  }

  function editJob(editedJob) {
    fetch(`/api/edit-job/${editedJob.jobId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedJob)
    })
      .then(response => response.json())
      .then(editedJobResponse => {
        const newJobList = [...props.jobs];
        for (let i = 0; i < newJobList.length; i++) {
          if (newJobList[i].jobId === editedJob.jobId) {
            newJobList.splice(i, 1, editedJobResponse);
          }
        } props.setJobs(newJobList);
      })
      .then(() => {
        const params = {
          yearId: weekAndYear.yearId,
          weekId: weekAndYear.weekId
        };
        fetch(`/api/weekly-total-copies/${params.yearId}/${params.weekId}`)
          .then(res => res.json())
          .then(totalWeekCopies => {
            setTotalWeekCopies(totalWeekCopies);
          })
          .then(() => {
            fetch(`/api/yearly-total-copies/${params.yearId}`)
              .then(res => res.json())
              .then(totalYearCopies => {
                setTotalYearCopies(totalYearCopies);
              });
          });
      })
      .catch(console.error);
  }

  function cancelJob(selectedJob) {
    fetch(`/api/cancel-job/${selectedJob.jobId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(selectedJob)
    })
      .then(res => res.json())
      .then(cancelledJob => {
        const newJobList = [...props.jobs];
        newJobList.forEach((event, index) => {
          if (newJobList[index].jobId === selectedJob.jobId) {
            newJobList.splice(index, 1, cancelledJob);
            props.setJobs(newJobList);
          }
        });
      })
      .then(() => {
        const params = {
          yearId: weekAndYear.yearId,
          weekId: weekAndYear.weekId
        };
        fetch(`/api/weekly-total-copies/${params.yearId}/${params.weekId}`)
          .then(res => res.json())
          .then(totalWeekCopies => {
            setTotalWeekCopies(totalWeekCopies);
          })
          .then(() => {
            fetch(`/api/yearly-total-copies/${params.yearId}`)
              .then(res => res.json())
              .then(totalYearCopies => {
                setTotalYearCopies(totalYearCopies);
              });
          });
      })
      .catch(console.error);
  }

  function unCancelJob(event) {
    const jobId = Number(event.target.id);
    fetch(`/api/unCancel-job/${jobId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ jobId })
    })
      .then(res => res.json())
      .then(unCancelledJob => {
        const newJobList = [...props.jobs];
        newJobList.forEach((event, index) => {
          if (newJobList[index].jobId === jobId) {
            newJobList.splice(index, 1, unCancelledJob);
            props.setJobs(newJobList);
          }
        });
      })
      .then(() => {
        const params = {
          yearId: weekAndYear.yearId,
          weekId: weekAndYear.weekId
        };
        fetch(`/api/weekly-total-copies/${params.yearId}/${params.weekId}`)
          .then(res => res.json())
          .then(totalWeekCopies => {
            setTotalWeekCopies(totalWeekCopies);
          })
          .then(() => {
            fetch(`/api/yearly-total-copies/${params.yearId}`)
              .then(res => res.json())
              .then(totalYearCopies => {
                setTotalYearCopies(totalYearCopies);
              });
          });
      })
      .catch(console.error);
  }

  function deleteJob(selectedJob) {
    fetch(`/api/delete-job/${selectedJob.jobId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(selectedJob)
    })
      .then(res => res.json())
      .then(deletedJob => {
        const newJobList = [...props.jobs];
        newJobList.forEach((event, index) => {
          if (newJobList[index].jobId === selectedJob.jobId) {
            newJobList.splice(index, 1);
            props.setJobs(newJobList);
          }
        });
      })
      .then(() => {
        const params = {
          yearId: weekAndYear.yearId,
          weekId: weekAndYear.weekId
        };
        fetch(`/api/weekly-total-copies/${params.yearId}/${params.weekId}`)
          .then(res => res.json())
          .then(totalWeekCopies => {
            setTotalWeekCopies(totalWeekCopies);
          })
          .then(() => {
            fetch(`/api/yearly-total-copies/${params.yearId}`)
              .then(res => res.json())
              .then(totalYearCopies => {
                setTotalYearCopies(totalYearCopies);
              });
          });
      });
  }

  function closeModal() {
    setValues({
      jobId: '',
      yearId: '',
      weekId: '',
      year: '',
      week: '',
      companyName: '',
      companyAddress: '',
      companyCity: '',
      companyState: '',
      companyZip: '',
      distributorName: '',
      distributorId: '',
      distributorAddressId: '',
      jobNumber: '',
      paperSize: '',
      paperWeight: '',
      shippingStatus: '',
      paymentStatus: '',
      orderStatus: '',
      distributorCopies: '',
      storeCopies: '',
      officeCopies: '',
      instructions: '',
      shipDate: '',
      dueDate: '',
      inHomeDate: '',
      headline: ''
    });
  }

  // // ChatGPT code for turning buttons green after being pressed: 👇🏼
  // // Add selectedWeek state
  const [selectedWeek, setSelectedWeek] = useState(null);

  const renderWeekButtons = (start, end) => {
    return newWeeksList.slice(start - 1, end).map(weekObj => {
      const isWeekSelected = selectedWeek === weekObj.weekId;

      return (
        <button
          key={weekObj.weekId}
          type="submit"
          className={`col-sm btn btn-sm week-btn col border border-dark ${isWeekSelected ? 'btn-success text-white' : 'btn-outline-dark'}`}
          onClick={event => handleButtonClick(event, weekObj.weekId)}
        >
          {weekObj.week}
        </button>
      );
    });
  };

  const handleButtonClick = (event, weekId) => {
    event.preventDefault();
    setSelectedWeek(selectedWeek === weekId ? null : weekId);

    let updatedSearchParams = {};

    const updateSearchParamsPromise = new Promise(resolve => {
      for (let i = 0; i < newWeeksList.length; i++) {
        if (Number(event.target.innerText) === newWeeksList[i].week) {
          updatedSearchParams = {
            ...searchParams,
            weekId: newWeeksList[i].weekId,
            week: newWeeksList[i].week
          };
          setSearchParams(updatedSearchParams);
          resolve(updatedSearchParams);
        }
      }
    });

    updateSearchParamsPromise.then(updatedSearchParams => {
      // This code will run after the promise is resolved
      const params = {
        yearId: updatedSearchParams.yearId,
        weekId: updatedSearchParams.weekId
      };
      if (params.yearId === weekAndYear.yearId && params.weekId === weekAndYear.weekId) {
        return (
          window.alert(`The page is already displaying week ${weekAndYear.week} of ${weekAndYear.year}`)
        );
      }
      props.onSubmit(params);
      fetch(`/api/weekly-total-copies/${params.yearId}/${params.weekId}`)
        .then(res => res.json())
        .then(totalWeekCopies => {
          setTotalWeekCopies(totalWeekCopies);
        })
        .then(() => {
          fetch(`/api/yearly-total-copies/${params.yearId}`)
            .then(res => res.json())
            .then(totalYearCopies => {
              setTotalYearCopies(totalYearCopies);
            });
        });
      setWeekAndYear({
        year: updatedSearchParams.year,
        yearId: updatedSearchParams.yearId,
        week: updatedSearchParams.week,
        weekId: updatedSearchParams.weekId
      });
    });
  };

  // FINISH 🏁
  const { user } = useContext(AppContext);
  if (!user) return <Redirect to="sign-in" />;
  return (
    <div>
      <div>
        <NavBar className="navbar" />
      </div>
      <section className="col week-section">
        <form>
          <div className="d-flex flex-wrap">
            <div className="col">
              <div className="d-flex">
                {renderWeekButtons(1, 13)}
              </div>
            </div>
            <div className="col">
              <div className="d-flex">
                {renderWeekButtons(14, 26)}
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col">
              <div className="d-flex">
                {renderWeekButtons(27, 39)}
              </div>
            </div>
            <div className="col">
              <div className="d-flex">
                {renderWeekButtons(40, 52)}
              </div>
            </div>
          </div>
        </form>
      </section>
      <div className="container">
        <div className="mt-3 d-flex flex-row flex-wrap justify-content-center">
          <div className="col-11 p-0 ">
            <div className=" d-flex main-title-wrapper">
              <h3 className="main-title me-1">Production Sheet</h3>
              {
                (typeof weekAndYear.year === 'number' &&
                  typeof weekAndYear.week === 'number' &&
                  props.jobs.length !== 0)
                  ? <div>
                    <h3 className="main-title ms-1"> | Week {weekAndYear.week} of {weekAndYear.year}</h3>
                  </div>
                  : <div />
              }
            </div>
            <div className="d-flex main-title-wrapper">
              {
                 (typeof weekAndYear.year === 'number' &&
                  typeof weekAndYear.week === 'number' &&
                  props.jobs.length !== 0 &&
                  !props.jobs[0].error &&
                  !totalWeekCopies.error &&
                  !totalYearCopies.error)
                   ? <div>
                     <h4 className="m-1 fw-light"> Weekly Totals: {totalWeekCopies}</h4>
                     <h4 className="m-1 fw-light yearly-totals"> YTD Totals: {totalYearCopies}</h4>
                   </div>
                   : <div />
              }
            </div>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-center mb-4">
          <div className="col-11 mt-2 p-0">
            <div className="d-flex calendar-plus-container">
              <div className="">
                <div className="dropdown">
                  <button
                    className="btn btn-transparent btn-sm dropdown-toggle"
                    type="button"
                    id="styledSelect"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa-solid fa-calendar-days me-2 text-primary " />
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="styledSelect">
                    { yearsList.map(event => (
                      <li key={event.yearId}>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={handleYearChange}
                        >
                          {event.year}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {
                user.isAdmin === true &&
                <div className="">
                  <div className="mb-3 d-flex flex-row justify-content-center">
                    <div>
                      <button className="btn btn-sm btn-transparent new-job-btn rounded-circle" onClick={showNewJobModal}>
                        <i className="fa-solid fa-plus text-primary" />
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {
          // ternary operator renders message when nothing has been searched yet 👇🏼
          (props.jobs.length === 0 && weekAndYear.yearId === '' && weekAndYear.weekId === '')
            ? <div className="col-12"><p className="text-center">Nothing to display yet.</p></div>
          // ternary operator renders message when nothing matches the search results 👇🏼
            : props.jobs[0] === undefined
              ? <div className="col-12"><p className="text-center">That search does not match any criteria.</p></div>
              // this is rendered when we successfully have search results, and it has to meet all the criteria:  👇🏼
              // - there have to actually be jobs available to view
              // - a year and a week have to be selected aka a search has to have been performed
              // - there can't have been an error received from the server, otherwise it renders an empty job
              : props.jobs.length !== 0 && typeof weekAndYear.yearId === 'number' && typeof weekAndYear.weekId === 'number' &&
                !props.jobs[0].error
                ? <div className="d-flex justify-content-center">
                  <div className="col-11">
                    <ul id="job-list">
                      {
                          props.jobs.map(event => {
                            return (
                              <li className="mt-3 mb-5 col-12 job-container" key={event.jobId}>
                                <div className="d-flex flex-row">
                                  <div className="col-10">
                                    <div className="d-flex flex-wrap mt-1">
                                      <p id="order-number" className="job-status m-2">{event.jobNumber}</p>
                                      {event.isCancelled === false ? <p id="order-status" className="job-status text-info m-2 ">{event.orderStatus}</p> : null}
                                      {event.isCancelled === false ? <p id="payment-status " className="job-status text-success m-2">{event.paymentStatus}</p> : null}
                                      {event.isCancelled === false ? <p id="shipping-status " className="job-status m-2">{event.shippingStatus}</p> : null}
                                      {event.isCancelled === true ? <p className="job-status text-danger m-2">CANCELLED</p> : <div />}
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <div className="d-flex justify-content-end pt-3">
                                      {
                                        user.isAdmin === true && event.isCancelled === false
                                          ? <div >
                                            <div className="">
                                              <div className="dropdown">
                                                <button
                                                  className="p-1 btn btn-transparent btn-sm dropdown-toggle"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false">
                                                  <i className="fa-solid fa-pen-to-square me-2 text-secondary"/>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="styledSelect">
                                                  {
                                                    event.isCancelled === false
                                                      ? <li>
                                                        <button
                                                          onClick={showEditModal}
                                                          id={event.jobId}
                                                          type="button"
                                                          className="dropdown-item">
                                                          Edit Job
                                                        </button>
                                                      </li>
                                                      : null
                                                  }
                                                  <li>
                                                    <button
                                                          className="dropdown-item"
                                                          type="button"
                                                          id={event.jobId}
                                                          onClick={showDuplicateJobModal}
                                                        >
                                                      Duplicate Job
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          : null
                                      }
                                      {
                                        user.isAdmin === true
                                          ? <div >
                                            <div className="">
                                              <div className="dropdown">
                                                <button
                                                  className="p-1 btn btn-transparent btn-sm dropdown-toggle"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                  id={event.jobId}
                                                  onClick={prepareToRemove}
                                                >
                                                  <i className="fa-solid fa-trash me-2 text-secondary" id={event.jobId} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="styledSelect">
                                                  {
                                                    event.isCancelled === false
                                                      ? <li>
                                                        <button
                                                          className="dropdown-item"
                                                          type="button"
                                                          id={event.jobId}
                                                          onClick={showCancelModal}
                                                        >
                                                          Cancel Job
                                                        </button>
                                                      </li>
                                                      : null
                                                  }
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                      id={event.jobId}
                                                      onClick={showDeleteModal}
                                                    >
                                                      Delete Job Permanently
                                                    </button>
                                                  </li>
                                                  {
                                                    event.isCancelled === true
                                                      ? <li>
                                                        <button
                                                          className="dropdown-item"
                                                          type="button"
                                                          id={event.jobId}
                                                          onClick={unCancelJob}
                                                        >
                                                          Undo Cancel
                                                        </button>
                                                      </li>
                                                      : null
                                                  }
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          : null
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex flex-row">
                                  <div className="col-12 box-shadow rounded">
                                    <div id="job-details-header-1" className="d-flex job-details-header p-1 rounded">
                                      <p className="col fw-bold ">Headline</p>
                                      <p className="col fw-bold ">Company Name</p>
                                    </div>
                                    <div id="job-details-1" className="d-flex job-details p-1">
                                      <p className="m-1 col">{event.headline}</p>
                                      <p id="company-name" className="m-1 col">{event.companyName}</p>
                                    </div>
                                    <div id="job-details-header-1" className="d-flex job-details-header p-1 rounded">
                                      <p className="col fw-bold m-1">Paper Size</p>
                                      <p className="col fw-bold m-1">Paper Weight</p>
                                      <p className="col fw-bold m-1">Ship Date</p>
                                      <p className="col fw-bold m-1">Due Date</p>
                                      <p className="col fw-bold m-1">In Home Date</p>
                                    </div>
                                    <div id="job-details-1" className="d-flex job-details p-1">
                                      {/* will need to figure out how to allow "quotation marks" */}
                                      <p id="paper-size" className="overflow-x m-1 col">{event.paperSize}</p>
                                      <p id="paper-weight" className="m-1 col">{event.paperWeight}</p>
                                      <p id="ship-date" className="text-danger m-1 col">{event.shipDate}</p>
                                      <p id="due-date" className="text-danger m-1 col">{event.dueDate}</p>
                                      <p id="in-home-date" className="text-danger m-1 col">{event.inHomeDate}</p>
                                    </div>
                                    <div id="job-details-header-2" className="d-flex job-details-header p-1">
                                      <p className="m-1 col fw-bold ">Instructions</p>
                                      <p className="m-1 col fw-bold">Store Copies</p>
                                      <p className="m-1 col fw-bold">{event.distributorName} Copies</p>
                                      <p className="m-1 col fw-bold">Office Copies</p>
                                      <p className="m-1 col fw-bold">Total Job Copies</p>
                                    </div>
                                    <div id="job-details-2 " className="d-flex job-details p-1">
                                      <p id="company-name" className="m-1 col">{event.instructions}</p>
                                      <p className="m-1 col">{event.storeCopies}</p>
                                      <p className="m-1 col">{event.distributorCopies}</p>
                                      <p className="m-1 col">{event.officeCopies}</p>
                                      {/* This says "totalCopies", but it has to do with the name of an attribute
                                      on the server side, not with the current state on the client side: */}
                                      <p className="m-1 col">{event.totalCopies}</p>
                                    </div>
                                    <div id="job-details-header-3 " className="d-flex job-details-header p-1">
                                      <p className="m-1 col fw-bold ">Destination</p>
                                      <p className="m-1 col fw-bold">Address</p>
                                      <p className="m-1 col fw-bold">City</p>
                                      <p className="m-1 col fw-bold">State</p>
                                      <p className="m-1 col fw-bold">Zip</p>
                                    </div>
                                    <div id="job-details-3 " className="d-flex job-details p-1">
                                      <p className="m-1 col ">{event.distributorName}</p>
                                      <p className="m-1 col">{event.distributorAddress}</p>
                                      <p className="m-1 col">{event.distributorCity}</p>
                                      <p className="m-1 col">{event.distributorState}</p>
                                      <p className="m-1 col">{event.distributorZip}</p>
                                    </div>
                                    <div id="job-details-3 " className="d-flex job-details p-1">
                                      <p className="m-1 col ">{event.companyName}</p>
                                      <p className="m-1 col">{event.companyAddress}</p>
                                      <p className="m-1 col">{event.companyCity}</p>
                                      <p className="m-1 col">{event.companyState}</p>
                                      <p className="m-1 col">{event.companyZip}</p>
                                    </div>
                                    <div id="job-details-3 " className="d-flex job-details p-1 rounded">
                                      <p className="m-1 col ">Color Ad</p>
                                      <p className="m-1 col">N/A</p>
                                      <p className="m-1 col">N/A</p>
                                      <p className="m-1 col">N/A</p>
                                      <p className="m-1 col">N/A</p>
                                    </div>
                                  </div>
                                </div>
                                <CancelJobModal onClick={showCancelModal} onSubmit={cancelJob} jobs={props.jobs} selectedJobId={selectedJobId} setSelectedJobId={setSelectedJobId}
                                  overlay={overlay} setOverlay={setOverlay} cancelModalStyle={cancelModalStyle} setCancelModalStyle={setCancelModalStyle} hideCancelModal={hideCancelModal} />
                                <DeleteJobModal onClick={showCancelModal} onSubmit={deleteJob} jobs={props.jobs} selectedJobId={selectedJobId} setSelectedJobId={setSelectedJobId}
                                  overlay={overlay} setOverlay={setOverlay} deleteModalStyle={deleteModalStyle} setDeleteModalStyle={setDeleteModalStyle} hideDeleteModal={hideDeleteModal} />
                              </li>
                            );
                          })
                        }
                    </ul>
                  </div>
                </div>
                : <div className="col-12"><p className="text-center">Nothing to display yet.</p></div>
        }
        <NewJobModal
          onSubmit={addJob} allJobs={props.allJobs} duplicateJobMessage={duplicateJobMessage} setDuplicateJobMessage={setDuplicateJobMessage}
          values={values} setValues={setValues} yearsList={yearsList} weeksList={weeksList}
          distributors={distributors} companies={companies}
          closeModal={closeModal} handleYearIdChange={handleYearIdChange}
          handleWeekIdChange={handleWeekIdChange} handleDistributorIdChange={handleDistributorIdChange} handleCompanyIdChange={handleCompanyIdChange}
          handleJobNumberChange={handleJobNumberChange} handlePaperSizeChange={handlePaperSizeChange}
          handlePaperWeightChange={handlePaperWeightChange} handleShippingStatusChange={handleShippingStatusChange}
          handlePaymentStatusChange={handlePaymentStatusChange} handleOrderStatusChange={handleOrderStatusChange}
          handleDistributorCopiesChange={handleDistributorCopiesChange} handleStoreCopiesChange={handleStoreCopiesChange}
          handleOfficeCopiesChange={handleOfficeCopiesChange} handleInstructionsChange={handleInstructionsChange}
          handleShipDateChange={handleShipDateChange} handleDueDateChange={handleDueDateChange} handleInHomeDateChange={handleInHomeDateChange}
          handleHeadlineChange={handleHeadlineChange}
          newJobOverlay={newJobOverlay} setNewJobOverlay={setNewJobOverlay}
          newJobModalStyle={newJobModalStyle} setNewJobModalStyle={setNewJobModalStyle} hideNewJobModal={hideNewJobModal} />

        <EditModal onSubmit={editJob} allJobs={props.allJobs} duplicateJobMessage={duplicateJobMessage} setDuplicateJobMessage={setDuplicateJobMessage}
          values={values} jobs={props.jobs} distributors={distributors} companies={companies}
          setValues={setValues} yearsList={yearsList} weeksList={weeksList} weekAndYear={weekAndYear} handleYearIdChange={handleYearIdChange}
          handleWeekIdChange={handleWeekIdChange} handleDistributorIdChange={handleDistributorIdChange} handleCompanyIdChange={handleCompanyIdChange}
          handleJobNumberChange={handleJobNumberChange} handlePaperSizeChange={handlePaperSizeChange}
          handlePaperWeightChange={handlePaperWeightChange} handleShippingStatusChange={handleShippingStatusChange}
          handlePaymentStatusChange={handlePaymentStatusChange} handleOrderStatusChange={handleOrderStatusChange}
          handleDistributorCopiesChange={handleDistributorCopiesChange} handleStoreCopiesChange={handleStoreCopiesChange}
          handleOfficeCopiesChange={handleOfficeCopiesChange} handleInstructionsChange={handleInstructionsChange}
          handleShipDateChange={handleShipDateChange} handleDueDateChange={handleDueDateChange} handleInHomeDateChange={handleInHomeDateChange}
          handleHeadlineChange={handleHeadlineChange}
          editModalStyle={editModalStyle} setEditModalStyle={setEditModalStyle}
          editOverlay={editOverlay} setEditOverlay={setEditOverlay} hideEditModal={hideEditModal} />
        <DuplicateJobModal onSubmit={duplicateJob}
          allJobs={props.allJobs} duplicateJobMessage={duplicateJobMessage} setDuplicateJobMessage={setDuplicateJobMessage}
          values={values} jobs={props.jobs} distributors={distributors} companies={companies}
          setValues={setValues} yearsList={yearsList} weeksList={weeksList} weekAndYear={weekAndYear} handleYearIdChange={handleYearIdChange}
          handleWeekIdChange={handleWeekIdChange} handleDistributorIdChange={handleDistributorIdChange} handleCompanyIdChange={handleCompanyIdChange}
          handleJobNumberChange={handleJobNumberChange} handlePaperSizeChange={handlePaperSizeChange}
          handlePaperWeightChange={handlePaperWeightChange} handleShippingStatusChange={handleShippingStatusChange}
          handlePaymentStatusChange={handlePaymentStatusChange} handleOrderStatusChange={handleOrderStatusChange}
          handleDistributorCopiesChange={handleDistributorCopiesChange} handleStoreCopiesChange={handleStoreCopiesChange}
          handleOfficeCopiesChange={handleOfficeCopiesChange} handleInstructionsChange={handleInstructionsChange}
          handleShipDateChange={handleShipDateChange} handleDueDateChange={handleDueDateChange} handleInHomeDateChange={handleInHomeDateChange}
          handleHeadlineChange={handleHeadlineChange}
          duplicateJobModalStyle={duplicateJobModalStyle} setDuplicateJobModalStyle={setDuplicateJobModalStyle}
          duplicateJobOverlay={duplicateJobOverlay} setDuplicateJobOverlay={setDuplicateJobOverlay}
          hideDuplicateJobModal={hideDuplicateJobModal}
          />
      </div>
    </div>
  );
}
